/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import ScrollReveal from 'scrollreveal';

import Header from './header';
import Footer from './footer';
import './layout.css';

const Layout = ({ children, pageSlug = '' }) => {
  useEffect(async () => {
    const scrollreveal = ScrollReveal({
      distance: '50px',
      duration: 1000,
      easing: 'ease',
      mobile: true,
      reset: false,
      viewFactor: 0.4,
    });

    scrollreveal.reveal('.container h1, .container h2', {
      origin: 'bottom',
    });
  });

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Epilogue:wght@300;400;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div id="grid24">
        <Header pageSlug={pageSlug} />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
