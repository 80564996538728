import { Link } from 'gatsby';
import React from 'react';

const Footer = () => (
  <>
    <section className="row bg-black footer">
      <div className="container">
        <div className="row padding-top-30 padding-bottom-10">
          <div className="row">
            <div className="col s24">
              <div className="box bs">
                <h4>Subscribe to our newsletter</h4>
              </div>
            </div>
          </div>
          <div className="col l10 m12 s24">
            <div className="box bs">
              <form id="subscribe">
                <input type="text" placeholder="Email" defaultValue="" />
              </form>
              <ul className="social">
                <li>
                  <a href="https://twitter.com/healthy2study" title="twitter">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/insure2study"
                    title="facebook"
                  >
                    <i className="fa fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/stayhealthy2study/"
                    title="instagram"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col l4 m4 s24 offset-l10 offset-m10 offset-s0">
            <div className="box bs">
              <h5>Sitemap</h5>
              <nav className="footer-nav">
                <ul>
                  <li className="menudepth0 first_child">
                    <Link className="" to="/">
                      <span>Home</span>
                    </Link>
                  </li>
                  <li className="menudepth0">
                    <Link className="" to="/schools-universities">
                      <span>Schools &amp; Universities</span>
                    </Link>
                  </li>
                  <li className="menudepth0">
                    <Link className="" to="/international-students">
                      <span>International Students</span>
                    </Link>
                  </li>
                  <li className="menudepth0">
                    <Link className="" to="/agents">
                      <span>Agents</span>
                    </Link>
                  </li>
                  <li className="menudepth0">
                    <Link className="" to="/testimonials">
                      <span>Testimonials</span>
                    </Link>
                  </li>
                  <li className="menudepth0 last_child menuactive">
                    <Link className="menuactive" to="/contact-us">
                      <span>Contact Us</span>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="row bg-black footer">
      <div className="container border-top-white">
        <div className="row padding-top-10 padding-bottom-30">
          <div className="col l16 m16 s24">
            <div className="box bs">
              <p>©2021 Payments2Study</p>
            </div>
          </div>
          <div className="col l4 m4 s24">
            <div className="box bs">
              <p>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </p>
            </div>
          </div>
          <div className="col l4 m4 s24">
            <div className="box bs">
              <p>
                <Link to="/terms-conditions">Terms &amp; Conditions</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default Footer;
