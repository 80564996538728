const navigation_items = [
  {
    id: 'home',
    link: '/',
    linkTitle: 'Home',
    classElement: { a: '', li: 'first_child' },
  },
  {
    id: 'schools-universities',
    link: '/schools-universities',
    linkTitle: 'Schools & Universities',
    classElement: { a: '', li: '' },
  },
  {
    id: 'international-students',
    link: '/international-students',
    linkTitle: 'International Students',
    classElement: { a: '', li: '' },
  },
  {
    id: 'agents',
    link: '/agents',
    linkTitle: 'Agents',
    classElement: { a: '', li: '' },
  },
  {
    id: 'testimonials',
    link: '/testimonials',
    linkTitle: 'Testimonials',
    classElement: { a: '', li: '' },
  },
  {
    id: 'contact-us',
    link: '/contact-us',
    linkTitle: 'Contact Us',
    classElement: { a: '', li: 'last_child' },
  },
];

module.exports = navigation_items;
