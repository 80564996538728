import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import navigation_items from '../content/navigation';
import Payments2StudyLogo from '../images/sitewide/p2s-logo@2x.png';

const Header = (pageSlug) => {
  const [isOpen, toggleOpen] = useState(false);

  return (
    <div className={`sticky ${isOpen ? 'menu-open menu-closed' : null}`}>
      <div className="container">
        <button
          onClick={() => toggleOpen(!isOpen)}
          className={`menu-btn hamburger hamburger--collapse ${
            isOpen ? 'is-active' : null
          }`}
          type="button"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </div>
      <section className="row">
        <div className="header container">
          <section id="header" className="row">
            <div className="col l6 m6 s10">
              <div className="box bm">
                <a className="logo" href="/">
                  <img src={Payments2StudyLogo} alt="Stay Healthy 2 Study" />
                </a>
              </div>
            </div>
          </section>
        </div>
        <div className="menu-container animate-all-slow">
          <nav id="nav" className="menu-nav">
            <ul>
              {navigation_items.map((item) => (
                <li
                  className={`menudepth0 ${item.classElement.li}`}
                  key={item.id}
                >
                  <Link
                    className={`${item.classElement.a} ${
                      pageSlug.pageSlug === item.id ? 'menuactive' : ''
                    }`}
                    to={`${item.link}`}
                  >
                    <span>{item.linkTitle}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="row header-space"></div>
      </section>

      <div className="row bg-grey bg-marque"></div>
    </div>
  );
};

Header.propTypes = {
  pageSlug: PropTypes.string,
};

Header.defaultProps = {
  pageSlug: ``,
};

export default Header;
